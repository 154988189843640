import React, {useMemo} from 'react';
import {Column} from "react-table";
import CustomHeader from "../../../../_mesomb/partials/table/CustomHeader.tsx";
import I18n from "../../../../_mesomb/i18n";
import MediaInfoCell from "../../../../_mesomb/partials/table/MediaInfoCell.tsx";
import {QUERIES} from "../../../../_mesomb/helpers";
import NumberCell from "../../../../_mesomb/partials/table/NumberCell.tsx";
import DateCell from "../../../../_mesomb/partials/table/DateCell.tsx";
import PaginatedList from "../../../../_mesomb/partials/table/PaginatedList.tsx";
import {camelize, countryFlagUrl} from "../../../../_mesomb/utils";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {WalletModel} from "../../../../types";
import {downloadFile} from "../../../../_mesomb/utils/downloader.ts";
import HStatus from "../../../../_mesomb/partials/components/HStatus.tsx";

function Wallets() {
  const {t} = useTranslation();
  const columns = useMemo<readonly Column<WalletModel>[]>(() => [
    // {
    //   Header: (props) => <SelectionHeader tableProps={props} />,
    //   id: 'selection',
    //   Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].id} />,
    // },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Name')} className='min-w-250px' />,
      id: 'number',
      Cell: ({...props}) => <MediaInfoCell media={{
        // url: `/wallets/${props.data[props.row.index].id}`,
        title: props.data[props.row.index].name,
        subtitle: props.data[props.row.index].number,
        // avatar: props.data[props.row.index].image_url ? toAbsoluteStatic(props.data[props.row.index].image_url) : undefined,
      }} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Phone Number')} className='w-120px' />,
      accessor: 'phone_number',
      // Cell: ({...props}) => <a href={`tel:${props.data[props.row.index].phone_number}`} className={'text-muted'}>{props.data[props.row.index].phone_number}</a>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Email')} className='w-120px' />,
      accessor: 'email',
      // Cell: ({...props}) => <DateCell value={props.data[props.row.index].created_at} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Gender')} className='w-120px' />,
      accessor: 'gender',
      // Cell: ({...props}) => <DateCell value={props.data[props.row.index].created_at} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Balance')} className='w-120px' />,
      accessor: 'balance',
      Cell: ({...props}) => <NumberCell value={props.data[props.row.index].balance} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Amount')} className='w-100px' />,
      accessor: 'status',
      Cell: ({...props}) => <HStatus status={props.data[props.row.index].status} label={t(camelize(props.data[props.row.index].status))} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Last Act')} className='w-125px' />,
      accessor: 'last_activity',
      Cell: ({...props}) => <DateCell value={props.data[props.row.index].last_activity} />,
    },
    // {
    //   Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Actions')} className='text-end min-w-100px' />,
    //   id: 'actions',
    //   // Cell: ({...props}) => (
    //   //   <div>
    //   //     <button className={'btn btn-icon btn-primary me-2'} onClick={() => {
    //   //       setSelected(props.data[props.row.index])
    //   //       setShowModal(true)
    //   //     }}><i className="fa-solid fa-pencil"></i></button>
    //   //     <ActionButton
    //   //       title={(<i className="fa-solid fa-trash"></i>)}
    //   //       action={{path: `/products/${props.data[props.row.index].id}/`, method: 'delete'}}
    //   //       className={'btn-icon btn-danger'}
    //   //       confirmation={{
    //   //         title: t('Confirmation'),
    //   //         message: t('Are you sur you want to preform this action? This action irreversible.'),
    //   //       }}
    //   //       onFinish={() => {
    //   //         ref.current?.click()
    //   //       }}
    //   //     />
    //   //   </div>
    //   // ),
    // },
  ], []);

  return (
    <>
      <PaginatedList
        // ref={ref}
        url={`/wallets/`}
        columns={columns}
        // title={(
        //   <button type='button' className='btn btn-primary' onClick={() => {
        //     setSelected(undefined)
        //     setShowModal(true)
        //   }}>
        //     <KTIcon iconName='plus' className='fs-2'/>
        //     {t('New Product')}
        //   </button>
        // )}
        showSearch={true}
        // actions={[
        //   {
        //     title: t('Export'),
        //     icon: 'exit-up',
        //     onClick: async () => {
        //       await downloadFile('/wallets/export/', 'wallets.csv')
        //     },
        //   }
        // ]}
        queryList={QUERIES.WALLET_LIST}
      />
      <Helmet>
        <title>{t('Wallets')}</title>
      </Helmet>
    </>
  )
}

export default Wallets;
