import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
// import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import './_mesomb/assets/sass/style.react.scss'
// import './_mesomb/assets/fonticon/fonticon.css'
import './_mesomb/assets/keenicons/duotone/style.css'
// import './_mesomb/assets/keenicons/outline/style.css'
// import './_mesomb/assets/keenicons/solid/style.css'
import './_mesomb/assets/sass/style.scss'
import {setupAxios} from './app/modules/auth'
import {store, persistor} from './app/store'
import {Provider} from "react-redux"
import App from "./app/App.tsx";
import {I18nextProvider} from "react-i18next";
import i18n from "./_mesomb/i18n";
import {register} from './serviceWorker';
import {PersistGate} from "redux-persist/integration/react";

setupAxios(axios, store)

if (store.getState().auth?.user?.locale) {
  i18n.changeLanguage(store.getState().auth?.user?.locale);
}

import('./firebase.ts').then(({initialize}) => {
  initialize()
});

(function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;
  w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
    m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
})(window, document,'script', `${import.meta.env.VITE_APP_ENVIRONMENT == 'development' ? 'https://mautic.ddev.site:33001' : 'https://marketing.hachther.com'}/mtc.js`,'mt');

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n} defaultNS={'translation'}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
          {/*<RouterProvider router={router} />*/}
            <App />
          </PersistGate>
        </Provider>
      </I18nextProvider>
      <ReactQueryDevtools initialIsOpen={false}/>
    </QueryClientProvider>
  )
}

register();

// window.SentryToolbar.init({
//   organizationSlug: "hachther",
//   projectIdOrSlug: "mesomb-react",
//   sentryOrigin: "https://sentry.hachther.com",
// });
