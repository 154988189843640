import React, {lazy, Suspense, useEffect} from 'react'
import {LayoutProvider, LayoutSplashScreen} from '../_mesomb/layout/core'
import {MasterInit} from '../_mesomb/layout/MasterInit'
import * as Sentry from "@sentry/react";
import {ThemeModeProvider, useThemeMode} from '../_mesomb/partials/layout/theme-mode/ThemeModeProvider'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  Navigate,
  Route,
  RouterProvider,
  useLocation,
  useNavigationType
} from "react-router";
import {useAppDispatch, useAppSelector} from "./store/hooks.ts";
import {selectUser, setAuth, setUser} from "./store/auth.ts";
import {ErrorsPage} from "./modules/errors/ErrorsPage.tsx";
import axios from "axios";
import EmailVerification from "./modules/auth/components/EmailVerification.tsx";
import {AuthPage, setAuthUrl} from "./modules/auth";
import {MasterLayout} from "../_mesomb/layout/MasterLayout.tsx";
import SuspensedView from "../_mesomb/partials/components/SuspensedView.tsx";
import ProductsPage from "./modules/product/ProductsPage.tsx";
import WalletPage from "./modules/wallet/WalletPage.tsx";
import {ThemeModeComponent} from "../_mesomb/assets/ts/layout";
import ErrorPage from "./modules/errors/ErrorPage.tsx";
import {setConfigs} from "./store/configs.ts";
import {setTerms} from "./store/terms.ts";
import Swal from "sweetalert2";
import i18n from "../_mesomb/i18n";
import linkMigration from "../data/links_ids.json";
import {selectAnnouncements, setAnnouncements} from "./store/announcements.ts";
import {
  APIAccessModel,
  AuthConfig, BulkPaymentExecutionModel,
  CustomerModel,
  OTPAuthenticatorModel,
  PaymentLinkModel,
  TransactionModel
} from "../types";
import {setCategories} from "./store/fund.ts";
import {CookieComponent} from "../_mesomb/assets/ts/components";
import PWABadge from "../PWABadge.tsx";


const ApplicationsPage = lazy(() => import('./modules/payment/ApplicationsPage'))
const FundsPage = lazy(() => import('./modules/fund/FundsPage'))
const FundContributionsPage = lazy(() => import('./modules/fund/FundContributionsPage'))
const PaymentLinkPage = lazy(() => import('./modules/paymentlink/PaymentLinkPage.tsx'))
const BulkPaymentPage = lazy(() => import('./modules/bulkpayment/BulkPaymentPage.tsx'))
const CustomersPage = lazy(() => import('./modules/customer/CustomersPage.tsx'))
const AccountPage = lazy(() => import('./modules/account/AccountPage.tsx'))
const TransactionsPage = lazy(() => import('./modules/transactions/TransactionsPage.tsx'))
const DashboardPage = lazy(() => import('./modules/home/DashboardPage.tsx'))
const ToolBoxPage = lazy(() => import('./modules/toolbox/ToolBoxPage.tsx'))
const TermsConditionsPage = lazy(() => import('./modules/auth/TermsConditionsPage.tsx'))

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(
  createBrowserRouter,
);

const extracKey = (params: Record<string, string>): string => {
  let key = params['*'];
  if ((key?.length || 0) > 0) {
    if (key.endsWith('/')) {
      key = key.slice(0, -1);
    }
    if (key?.indexOf('/') > 0) {
      key = key.split('/')[0]
    }
  }
  return key
}

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark';

const App = () => {
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const {mode} = useThemeMode()
  const calculatedMode = mode === 'system' ? systemMode : mode;
  const announcements = useAppSelector(selectAnnouncements);

  const feedback = Sentry.feedbackIntegration({
      colorScheme: calculatedMode,
      autoInject: false,
      showBranding: false,
    })

  useEffect(() => {
    Sentry.init({
      dsn: "https://82cc5e3d55a36285c91a17358ccd22d3@sentry.hachther.com/31",
      integrations: [
        Sentry.linkedErrorsIntegration({
          limit: 7,
        }),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        feedback,
        Sentry.reactRouterV7BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.extraErrorDataIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.httpContextIntegration(),
        Sentry.breadcrumbsIntegration({
          console: true,
          dom: true,
          fetch: true,
          history: true,
          xhr: true,
        })
      ],
      environment: import.meta.env.VITE_APP_ENVIRONMENT,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/mesomb\.hachther\.com\/api/
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      profilesSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      sendDefaultPii: true,
      beforeSend(event, hint) {
        if (import.meta.env.VITE_APP_ENVIRONMENT != 'production') {
          console.error(event);
          return
        }
        if (['ERR_NETWORK', 'ETIMEDOUT', 'ECONNABORTED'].includes(hint?.originalException?.code)) {
          return;
        }
        // Check if it is an exception, and if so, show the report dialog
        // if (event.exception && event.event_id) {
        //   Sentry.showReportDialog({eventId: event.event_id});
        // }
        return event;
      },
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'xhr') {
          const data = {
            ...(breadcrumb.data || {}),
            body: hint?.xhr?.__sentry_xhr_v3__?.body,
          }
          if (hint?.xhr?.status >= 400) {
            data.response = hint?.xhr.responseText.substring(0, 300)
          }
          return { ...breadcrumb, data }
        }
        return breadcrumb;
      }
    });

    axios.get('configs/').then(({data}) => {
      dispatch(setCategories(data.fund_categories))
      delete data.fund_categories;
      dispatch(setConfigs({
        ...data,
        providers: data.providers.reduce((acc: Record<string, any>, provider: any) => {
          acc[provider.key] = provider;
          return acc;
        }, {}),
      }))
    });
    if (currentUser) {
      Sentry.setUser({
        id: currentUser!.id,
        email: currentUser!.email,
        username: currentUser!.username,
        geo: {
          country_code: currentUser!.country,
          region: currentUser!.region,
          city: currentUser!.town,
        }
      })

      axios.get(setAuthUrl('auth/session')).then(({data}) => {
        dispatch(setUser(data.data.user));
        Promise.all([
          axios.get('terms/'),
          axios.get('announcements/')
        ]).then(([terms, announcements]) => {
          if (terms?.data) {
            dispatch(setTerms(terms.data))
          }
          if (announcements?.data) {
            dispatch(setAnnouncements(announcements.data));
          }
        })
      });

    }
    const ws = new WebSocket(`${import.meta.env.VITE_APP_SOCKET_HOST}/notificator/`)
    ws.onmessage = (message: string) => {
      const data = JSON.parse(message.data);
      if (data.type === 'announcement') {
        const index = announcements.findIndex(a => a.id == data.data.id)
        if (index > 0) {
          announcements[index] =  data.data;
          dispatch(setAnnouncements(announcements))
        } else {
          dispatch(setAnnouncements([...announcements, data.data]))
        }
      }
    }

    return () => {
      ws.close();
    };
  }, []);

  const {VITE_APP_ENVIRONMENT} = import.meta.env;
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route
          path='/auth/confirm-email/:key'
          element={<EmailVerification/>}
          loader={async ({params}) => {
            const {data} = await axios.post(setAuthUrl('auth/email/verify'), {key: params.key})
            dispatch(setAuth(data.meta))
            if (data.meta.is_authenticating) {
              const {data: profile} = await axios.get('profile/')
              dispatch(setUser(profile))
              window.location.href = '/dashboard'
            } else {
              window.location.href = '/auth/login'
            }
            return {}
          }}
        />
        <Route
          path='/invitations/accept-invite/:key'
          loader={async ({params}) => {
            const {data} = await axios.post(`profile/accept-invite/${params.key}/`)
            if (data.meta.is_authenticating) {
              const {data: profile} = await axios.get('profile/')
              dispatch(setUser(profile))
              window.location.href = '/dashboard'
            } else {
              if (data.message) {
                const result = await Swal.fire({
                  title: i18n.t('Error'),
                  text: data.message.text,
                  icon: data.message.type,
                  buttonsStyling: false,
                  confirmButtonText: i18n.t('Ok got it'),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary"
                  }
                })
                if (result.isConfirmed) {
                  window.location.href = data.redirect;
                }
              } else {
                window.location.href = data.redirect;
              }
            }
            return {}
          }}
        />
        {currentUser ? (
          <>
            <Route
              path='/*'
              element={<MasterLayout />}
              errorElement={VITE_APP_ENVIRONMENT != 'production' ? undefined : <ErrorPage feedback={feedback} />}
            >
              <Route
                path='auth/*'
                // element={<Navigate to={localStorage.getItem('redirect') ?? '/dashboard'} />}
                loader={async ({request}) => {
                  if (request.url.endsWith('logout')) {
                    try {
                      await axios.delete(setAuthUrl('auth/session'))
                    } catch (e) {
                    }
                    finally {
                      CookieComponent.delete('sessionid');
                      localStorage.clear();
                      location.href = '/auth/login'
                    }
                    return ;
                  }
                  location.href = localStorage.getItem('redirect') ?? '/dashboard';
                  localStorage.removeItem('redirect');
                  return {}
                }}
              />
              <Route path='dashboard/*' element={<SuspensedView><DashboardPage product={'business'} /></SuspensedView>} />
              <Route path='toolbox/*' element={<SuspensedView><ToolBoxPage product={'business'} /></SuspensedView>} />
              <Route path='applications/*' element={<SuspensedView><ApplicationsPage /></SuspensedView>} />
              <Route
                path='fundraising/contributions/*'
                element={<SuspensedView><FundContributionsPage /></SuspensedView>}
                loader={async ({params}) => {
                  const result: {contribution?: TransactionModel} = {}
                  const key = extracKey(params) ?? ''
                  if (key.length > 0 && !['add', 'contributions'].includes(key)) {
                    const {data} = await axios.get<TransactionModel>(`fundraising/contributions/${key}/`);
                    result.contribution = data;
                  }
                  return result
                }}
              />
              <Route
                path='fundraising/funds/*'
                element={<SuspensedView><FundsPage /></SuspensedView>}
              />
              <Route
                path='transactions/*'
                element={<SuspensedView><TransactionsPage /></SuspensedView>}
                loader={async ({params}) => {
                  const result: {transaction?: TransactionModel} = {}
                  const key = extracKey(params) ?? ''
                  if (key.length > 0 && key != 'add') {
                    const {data} = await axios.get<TransactionModel>(`business/transactions/${key}/`);
                    result.transaction = data;
                  }
                  return result
                }}
              />
              <Route
                path='business/links/*'
                element={<SuspensedView><PaymentLinkPage /></SuspensedView>}
              />
              <Route
                path='bulk/payments/*'
                element={<SuspensedView><BulkPaymentPage /></SuspensedView>}
              />
              <Route
                path='bulk/executions/*'
                element={<SuspensedView><BulkPaymentPage type={'executions'} /></SuspensedView>}
                loader={async ({params}) => {
                  const result: {execution?: BulkPaymentExecutionModel} = {}
                  const key = extracKey(params) ?? ''
                  if (key?.length > 0 && key != 'add') {
                    const {data} = await axios.get<BulkPaymentExecutionModel>(`bulk/executions/${key}/`);
                    result.execution = data;
                  }
                  return result
                }}
              />
              <Route path='products/*' element={<ProductsPage />} />
              <Route path='wallet/*' element={<WalletPage />} />
              <Route path='customers/*' element={<CustomersPage />} loader={async ({params}) => {
                const result: {customer?: CustomerModel} = {}

                const key = extracKey(params) ?? '';
                if (key.length > 0 && key != 'add') {
                  const {data} = await axios.get<CustomerModel>(`customers/${key}/`);
                  result.customer = data;
                }

                return result;
              }} />
              <Route
                path='settings/*'
                element={<AccountPage />}
                loader={async ({params}) => {
                  const result: {
                    otp?: {authenticators: OTPAuthenticatorModel; secret?: string;};
                    apiaccess: APIAccessModel[];
                  } = {}
                  const endpath = params['*']
                  if (endpath?.startsWith('profile')) {
                    const {data: {data: authenticators}} = await axios.get<{status: number; data?: OTPAuthenticatorModel; meta?: {secret: string}}>(setAuthUrl('account/authenticators'));
                    result.otp = {authenticators: authenticators!};
                    try {
                      await axios.get<OTPAuthenticatorModel>(setAuthUrl('account/authenticators/totp'));
                    } catch (error) {
                      if(axios.isAxiosError(error)) {
                        result.otp.secret = error.response?.data.meta.secret
                      }
                    }
                  }
                  if (endpath?.startsWith('developer')) {
                    const {data} = await axios.get<APIAccessModel[]>('apiaccess/');
                    result.apiaccess = data;
                  }
                  return result;
                }} />
              <Route path='terms-and-conditions' element={<TermsConditionsPage />} />
              <Route path='.well-known/apple-app-site-association' element={<TermsConditionsPage />} />
            </Route>
            <Route index element={<Navigate to='/dashboard' />} />
          </>
        ) : (
          <>
            <Route
              path='/auth/*'
              element={<AuthPage />}
              loader={async () => {
                const {data} = await axios.get<{data: AuthConfig}>(setAuthUrl('config'));
                return data.data;
              }}
            />
            <Route
              path='/web/payment/'
              loader={async ({request}) => {
                const link = linkMigration.find(l => String(l.id) == new URL(request.url).searchParams.get('widget'));
                location.href = `/pay/${link?.key}`
              }}
            />
            <Route
              path='/:lang/web/payment/'
              loader={async ({request}) => {
                const link = linkMigration.find(l => String(l.id) == new URL(request.url).searchParams.get('widget'));
                location.href = `/pay/${link?.key}`
              }}
            />
            <Route path='/terms-and-conditions' element={<TermsConditionsPage />} />
            <Route
              path='*'
              element={<Navigate to='/auth/login' />}
              loader={async ({request}) => {
                localStorage.setItem('redirect', URL.parse(request.url).pathname);
                return;
              }}
            />
          </>
        )}
      </>
    )
  );

  return (
    <Sentry.ErrorBoundary
      fallback={({error}) => {
        return (
          <ErrorPage error={error} feedback={feedback} />
        )
      }}
    >
      <Suspense fallback={<LayoutSplashScreen/>}>
        <LayoutProvider>
          <ThemeModeProvider>
            <MasterInit/>
            <RouterProvider router={router} />
          </ThemeModeProvider>
        </LayoutProvider>
      </Suspense>
      {/*<PWABadge />*/}
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(App);
