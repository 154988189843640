import React, {useMemo, useState} from 'react';
import PaginatedList from "../../../../_mesomb/partials/table/PaginatedList.tsx";
import {Column} from "react-table";
import {useTranslation} from "react-i18next";
import SelectionHeader from "../../../../_mesomb/partials/table/SelectionHeader.tsx";
import SelectionCell from "../../../../_mesomb/partials/table/SelectionCell.tsx";
import CustomHeader from "../../../../_mesomb/partials/table/CustomHeader.tsx";
import I18n from "../../../../_mesomb/i18n";
import DateCell from "../../../../_mesomb/partials/table/DateCell.tsx";
import NumberCell from "../../../../_mesomb/partials/table/NumberCell.tsx";
import HStatus from "../../../../_mesomb/partials/components/HStatus.tsx";
import {camelize} from "../../../../_mesomb/utils";
import MediaInfoCell from "../../../../_mesomb/partials/table/MediaInfoCell.tsx";
import {KTIcon, QUERIES, toAbsoluteStatic} from "../../../../_mesomb/helpers";
import ModalFormBuilder from "../../../../_mesomb/partials/components/ModalFormBuilder.tsx";
import * as Yup from 'yup'
import {ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet";
import {useAppSelector} from "../../../store/hooks.ts";
import {selectApplications} from "../../../store/application.ts";
import {FormFieldProps, WalletTransactionModel} from "../../../../types";
import {selectConfigs} from "../../../store/configs.ts";
import {getPaymentOperations} from "../../../../_mesomb/utils/operations.ts";

function Transactions() {
  const {t} = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const configs = useAppSelector(selectConfigs)

  const formExportSchema = useMemo(() => (Yup.object().shape({
    format: Yup.string().required(t('Field_is_required')),
    sdate: Yup.date().nullable(),
    edate: Yup.date().nullable(),
    directions: Yup.array().of(Yup.string())
    // recurrence: Yup.string().required(t('Field_is_required')),
    // amount: Yup.number().required(t('Field_is_required')),
    // b_party: Yup.string().matches(/^[0-9]{8,9}$/, t('Invalid_phone_number')).required(t('Field_is_required')),
  })), [])
  type FormExportValues = Yup.InferType<typeof formExportSchema>;

  const columns = useMemo<readonly Column<WalletTransactionModel>[]>(() => [
    {
      Header: (props) => <SelectionHeader tableProps={props}/>,
      id: 'selection',
      Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].id}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Receiver')} className='min-w-150px'/>,
      id: 'party_id',
      Cell: ({...props}) => {
        const r = props.data[props.row.index];
        return (
          <MediaInfoCell media={{
            // url: `${isBulk ? '/bulk' : ''}/transactions/${r.id}`,
            title: t(`Apitrx_${r.type}`),
            subtitle: r.wallet,
            // avatar: configs.providers[r.provider]?.logo ? toAbsoluteStatic(configs.providers[r.provider].logo) : undefined,
            // merchant: r.details?.merchant
          }}/>
        );
      },
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Status')} className='w-80px'/>,
      accessor: 'status',
      Cell: ({...props}) => <HStatus label={t(camelize(props.data[props.row.index].status.toLowerCase()))}
                                     status={props.data[props.row.index].status}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Amount')} className='w-110px'/>,
      accessor: 'amount',
      Cell: ({...props}) => {
        const r = props.data[props.row.index];
        return (
          <NumberCell
            value={r.amount}
            currency={r.currency}
            direction={r.direction}
          />
        );
      },
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Date')} className='w-110px'/>,
      accessor: 'date',
      Cell: ({...props}) => <DateCell value={props.data[props.row.index].date}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Operation ID')} className='w-80px'/>,
      accessor: 'fin_trx_id',
      // Cell: ({...props}) => <DateCell value={props.data[props.row.index].ts} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={I18n.t('Ext. ID')} className='w-120px'/>,
      accessor: 'external_id',
      // Cell: ({...props}) => <HeaderCell title={I18n.t(camelize(props.data[props.row.index].recurrence.toLowerCase()))} subtitle={props.data[props.row.index].next_run ? formatDate(props.data[props.row.index].next_run) : undefined} />,
    },
  ], []);
  console.dir(getPaymentOperations(configs, []))

  return (
    <>
      <PaginatedList
        url={'/wtransactions/'}
        columns={columns}
        showSearch={true}
        filters={[
          {
            name: 'status',
            title: t('Status'),
            options: [
              {value: '1', label: t('Success')},
              {value: '2', label: t('Collected')},
              {value: '3', label: t('Refunded')},
              {value: '4', label: t('Ongoing')},
              {value: '-1', label: t('Failed')},
              {value: '-2', label: t('Pending')},
              {value: '-3', label: t('Errored')},
              {value: '0', label: t('Started')},
            ]
          },
          {
            name: 'type',
            title: t('Type'),
            options: Object.values(getPaymentOperations(configs, [])).map(op => ({value: op.name, label: op.titles[I18n.language] || op.titles.en}))
          },
          {
            name: 'provider',
            title: t('Service'),
            options: [
              {value: 'MTN', label: t('Mobile Money')},
              {value: 'ORANGE', label: t('Orange Money')},
            ]
          },
          // {
          //   name: 'date',
          //   title: t('Date'),
          //   type: 'date',
          //   // options: [
          //   //   {value: 'DEPOSIT', label: t('Deposit')},
          //   //   {value: 'PAYMENT', label: t('Payment')},
          //   // ]
          // },
        ]}
        actions={[
          {
            title: t('Export'),
            icon: 'exit-up',
            onClick: () => {
              setShowModal(true)
            }
          }
        ]}
        queryList={QUERIES.WALLET_TRANSACTION_LIST}
      />
      <ModalFormBuilder<FormExportValues, BlobPart>
        show={showModal}
        title={t('Export Transaction')}
        fieldsets={[{
          cols: [
            {
              fields: [
                {
                  type: 'select',
                  name: 'format',
                  label: t('Select Export Format'),
                  options: ['CSV', 'XLS', 'XLSX', 'TSV', 'JSON'].map(f => ({value: f, label: f}))
                },
              ]
            }
          ]
        }, {
          cols: [
            {
              size: 'col-lg-6 col-md-12',
              fields: [
                {
                  type: 'date',
                  name: 'sdate',
                  label: t('From'),
                  required: false,
                },
              ]
            },
            {
              size: 'col-lg-6 col-md-12',
              fields: [
                {
                  type: 'date',
                  name: 'edate',
                  label: t('To'),
                  required: false,
                },
              ]
            }
          ]
        }]}
        formSchema={formExportSchema}
        handleClose={() => setShowModal(false)}
        action={{
          path: '/business/transactions/export/',
          method: 'post',
          responseType: 'blob'
        }}
        onSubmit={(data, submitted) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = `transactions.${submitted.format.toLowerCase()}`;
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }}
        initialValues={{
          format: 'CSV'
        }}
      />

      <ToastContainer/>
      <Helmet>
        <title>{t('Wallet Transactions')}</title>
      </Helmet>
    </>
  );
}

export default Transactions;
