import React, {lazy, useEffect, useState} from 'react';
import HIcon from "../../payment/components/HIcon.tsx";
import HStatus from "../../../../_mesomb/partials/components/HStatus.tsx";
import {KTIcon} from "../../../../_mesomb/helpers";
import ActionButton from "../../../../_mesomb/partials/components/ActionButton.tsx";
import Stat from "../../../../_mesomb/partials/components/Stat.tsx";
import {formatDate} from "date-fns/format";
import ModalProductForm from "./ModalProductForm.tsx";
import {useNavigate, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {selectProducts, setProduct} from "../../../store/product.ts";
import ListLoading from "../../../../_mesomb/partials/components/ListLoading.tsx";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {ProductModel} from "../../../../types";
import {formatNumberAbbr} from "../../../../_mesomb/utils";
import Swal from "sweetalert2";
import clsx from "clsx";
import {selectUser} from "../../../store/auth.ts";

const HChart = lazy(() => import('../../../../_mesomb/partials/components/HChart.tsx'))

function Product() {
  const params = useParams();
  const products = useSelector(selectProducts);
  const user = useSelector(selectUser);
  const product = products.find(a => a.id === params.id)
  const navigate = useNavigate();
  const {t} = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProduct(product))
    axios.get<ProductModel>(`products/${params.id}/`).then(({data}) => {
      dispatch(setProduct(data))
    })
  }, []);

  if (!product) {
    return <ListLoading />
  }

  return (
    <>
      <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
        <div className="symbol symbol-60px symbol-fixed position-relative me-5">
          <HIcon image={product.image_url} text={product.name} size={60}/>
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mb-1">
                <a href="#"
                   className="text-gray-800 text-hover-primary fs-2 fw-bold me-3">{product.name}</a>
                <HStatus label={product.archived ? t('Archived') : t('Active')} status={product.archived ? 'ARCHIVED' : 'ACTIVE'} />
              </div>
              <div className="d-flex flex-wrap fw-semibold fs-5 text-gray-400">{t('intlNumber', {value: product.default_price, currency: product.default_price_currency})}</div>
            </div>
            <div className="d-flex mb-4">
              <a onClick={() => {
                setShowModal(true)
              }}
                 className="btn btn-light-primary btn-sm me-3" style={{height: 35}}>
                <KTIcon iconName={'pencil'} />
                {t('Edit')}
              </a>
              <div className='me-0'>
                <button
                  className={'btn btn-outline btn-icon btn-outline-dashed'}
                  style={{height: 35}}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-cy={'btn-option-menu'}
                >
                  <KTIcon iconName={'dots-vertical'} className={'fs-3x'} />
                </button>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                  data-kt-menu="true">
                  <div className="menu-link flex-stack px-3">
                    <div className="menu-item px-1">
                      <a
                        className={'menu-link flex-stack px-3'}
                        onClick={async () => {
                          const {data} = await axios.patch(`/products/${product.id}/`, {archived: !product.archived})
                          dispatch(setProduct({...data, archived: !product.archived}))
                        }}
                      >
                        <span className="menu-text">{product.archived ? t('Activate') : t('Archive')}</span>
                      </a>
                    </div>
                    <div className="menu-item px-1">
                      <a
                        onClick={async () => {
                          if (!user!.in_live_mode || !product.in_live_mode) {
                            return
                          }
                          const {data} = await axios.post(`/products/${product.id}/copy/live/`)
                          dispatch(setProduct(data))
                        }}
                        className={clsx('menu-link flex-stack px-3', {'text-muted': !user!.in_live_mode || !product.in_live_mode})} data-kt-menu-trigger="click">
                        {t('Copy to live mode')}
                      </a>
                    </div>
                    {!product.archived && (
                      <div className="menu-item px-1">
                        <a onClick={async () => {
                          const value = await Swal.fire({
                            title: t('Confirmation'),
                            text: t('Product_delete_confirmation'),
                            icon: 'warning',
                            buttonsStyling: false,
                            confirmButtonText: t('Yes_do_it'),
                            customClass: {
                              confirmButton: "btn fw-bold btn-light-primary"
                            }
                          });
                          if (value.isConfirmed) {
                            await axios.delete(`/products/${product.id}/`);
                            navigate('/products/')
                          }
                        }}
                           className="menu-link flex-stack px-3 text-danger">{t('Delete')}</a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'row'}>
        <div className={'col-lg-9'}>
          <div className="card card-flush overflow-hidden">
            <div className="card-header py-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">{t('Last 30 days Sells')}</span>
              </h3>
            </div>
            <div className="card-body d-flex justify-content-between flex-column p-5">
              <HChart type={'area'} data={product.history ?? []} height={300}/>
            </div>
          </div>
        </div>
        <div className={'col-lg-3'}>
          <h2 className={'mb-3'}>{t('Insights')}</h2>
          <div className={'mb-5'}>
            <div className="fw-semibold fs-6 text-gray-900">{t('Total Sells')}</div>
            <span className={'text-gray-600'}>{t('intlNumber', {value: product.sells})}</span>
          </div>
          <div className={'mb-5'}>
            <div className="fw-semibold fs-6 text-gray-900">{t('Last Sell')}</div>
            <span className={'text-gray-600'}>{product.last_sell ? formatDate(product.last_sell!, 'PP') : '-'}</span>
          </div>

          <div className={'d-flex flex-row'}>
            <div className={'w-100 align-content-center'}>
              <h2 className={'d-flex'} style={{margin: 0}}>{t('Details')}</h2>
            </div>
            <button className={'btn btn-sm'} style={{width: 45}} onClick={() => setShowModal(true)}>
              <KTIcon iconName={'pencil'} className={'fs-2'} />
            </button>
          </div>
          <div className={'mb-5'}>
            <div className="fw-semibold fs-6 text-gray-900">{t('Description')}</div>
            <span className={'text-gray-600'}>{product.description ?? '-'}</span>
          </div>
          <div className={'mb-5'}>
            <div className="fw-semibold fs-6 text-gray-900">{t('Created At')}</div>
            <span className={'text-gray-600'}>{formatDate(product.created_at, 'PP')}</span>
          </div>
        </div>
      </div>

      <ModalProductForm
        show={showModal}
        onClose={() => setShowModal(false)}
        selected={product}
        onFinish={(data) => {
          dispatch(setProduct({...product, ...data}))
          setShowModal(false)
        }}
      />
    </>
  );
}

export default Product;
