import React from 'react';
import {Outlet, Route} from "react-router";
import {PageLink, PageTitle} from "../../../_mesomb/layout/core";
import i18n from "../../../_mesomb/i18n";
import {useTranslation} from "react-i18next";
import Wallets from "./components/Wallets.tsx";
import SentryRoutes from "../../../_mesomb/helpers/SentryRoutes.ts";
import Transactions from "./components/Transactions.tsx";

const breadcrumbs: PageLink[] = [
  {
    title: i18n.t('Wallet System'),
    path: '/wallet/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function WalletsPage() {
  const {t} = useTranslation()
  return (
    <SentryRoutes>
      <Route element={<Outlet />}>
        <Route
          path='wallets/'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>{t('Wallets')}</PageTitle>
              <Wallets />
            </>
          }
        />
        <Route
          path='transactions/'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>{t('Transactions')}</PageTitle>
              <Transactions />
            </>
          }
        />
      </Route>
    </SentryRoutes>
  );
}

export default WalletsPage;
